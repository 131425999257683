@import "../styles.module";
.our_astrologers_cont {
  font-family: "Poppins", sans-serif;
  @include setContentWidth;
  margin: 8rem auto;
  position: relative;
  border-radius: 50px;

  @include for-phone-only {
    margin: 4rem auto;
    display: flex;
    flex-direction: column;
  }

  .astrologers_cont {
    margin: 4rem 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.75rem;

    @include for-md-only {
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }

    @include for-phone-only {
      margin: 1rem 0;
      padding: 1rem;
      display: flex;
      flex-wrap: wrap;
      // overflow-x: scroll;
      @include hideScrollBar;
    }

    .expert_img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;
      //opacity: 0.8;
      //mix-blend-mode: darken;
    }
  }
}
