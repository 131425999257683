@use "sass:meta";
@use "sass:string";
@mixin setContentWidth {
  width: 75%;
  max-width: 1440px;
  @media (max-width: 1200px) {
    width: 85%;
  }

  @media (max-width: 900px) {
    width: 75%;
  }

  @media (max-width: 600px) {
    width: 90%;
  }
}

@mixin setContentColor {
  color: black;
}
$astro-content-width: 75%;

@mixin hideScrollBar {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

@mixin for-phone-only {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin for-xl-only {
  @media (max-width: 1550px) {
    @content;
  }
}
@mixin for-md-only {
  @media (max-width: 1250px) {
    @content;
  }
}

@mixin for-ipad-only {
  @media (max-width: 900px) {
    @content;
  }
}
@mixin for-sm-only {
  @media (max-width: 600px) {
    @content;
  }
}

.landing_page_cont {
  font-family: "Poppins", sans-serif;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: scroll;
  background: linear-gradient(
    359.88deg,
    #e2e4ff 1.49%,
    #fff5f5 48.78%,
    #ededed 92.44%
  );
}
.landing_page_cont {
  --primary: #2c2334;
  --secondary: #644381;
  --terinary: #ffffff;
  --primaryBackground1: linear-gradient(
    99deg,
    #7166f9 0%,
    #9686fc 51.61%,
    #84c4ff 102.15%
  );
  --primaryBackground2: #ffffff;
  --primaryBackground3: linear-gradient(146deg, #b18bfd 13.8%, #6846eb 93.2%);
  --primaryBackground4: linear-gradient(41deg, #ff6132 1.02%, #ffb872 86.11%);

  --primaryBackgroundContainer: linear-gradient(
    165.77deg,
    #8a5bed -9.28%,
    #5739cd 106.89%
  );
  --primaryText: #2c2334;
  --secondaryText: #644381;
  --primaryBackground: #ffffff;
  --secondaryBackground: linear-gradient(
    99deg,
    #7166f9 0%,
    #9686fc 51.61%,
    #84c4ff 102.15%
  );
  --brand-primary: #4e0b50;
  --brand-primary-rgb: rgb(78, 11, 80);
  --brand-secondary: #f1673d;
  --link-color: #4a90e2;
  --text-color-error: #d0021b;
  --input-border-color: #979797;
  --contest-background-color: #260e2e;
  --contest-primary-color: #ff4843;
  --contest-secondary-color: #fff;
  --contest-font-color3: #e58150;
  --contest-header-bg-color: var(--primary-color);
  --contest-background-grad: radial-gradient(
    43.24% 43.24% at 50% 24.06%,
    #4a0e43 0%,
    #260e2e 100%
  );
}

.view_more_btn {
  position: relative;
  top: -56px;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: "Poppins", sans-serif;
  color: white;
  width: 213px; // 213
  min-width: 140px;
  //margin-left: auto;
  outline: none;
  background: linear-gradient(125.75deg, #ffa767 -8.85%, #eb468b 90.19%);
  height: 60px;
  border: 2px solid rgba(255, 255, 255, 1);
  border-radius: 40px;
  img {
    margin-left: 15px;

    @include for-xl-only {
      width: 12px;
    }
  }

  @include for-xl-only {
    font-size: 14px;
    border-radius: 40px;
    width: 177px;
    height: 58px;
    //padding: 11px 24px;
  }
  @include for-phone-only {
    float: none;
    top: 0;
    margin: auto;
    height: 40px;
    padding: 4px 10px;
    font-size: 12px;
    line-height: 18px;
    order: 3;
  }
}

.section_heading {
  font-size: 56px; //56
  line-height: 56px;
  //font-size: 56px;
  font-weight: 700;
  text-align: center;
  color: rgba(0, 0, 0, 1);
  text-transform: uppercase;
  @include for-xl-only {
    font-size: 36px;
    line-height: 56px;
  }
  @include for-phone-only {
    font-size: 20px;
    line-height: 33px;
    width: 100%;
  }
  @include for-sm-only {
    font-size: 16px;
    line-height: 22px;
    width: 100%;
  }
}
