@import '../styles.module';
.download_banner_cont {
  font-family: "Poppins", sans-serif;
  @include setContentWidth;
  margin: 12rem auto;
  position: relative;
  border-radius: 60px;
  height: 619px;
  background: linear-gradient(145.78deg, #B18BFD 13.8%, #6846EB 93.2%);
  display: flex;
  align-items: center;

  @include for-phone-only{
    margin: 4rem auto;
    flex-direction: column-reverse;
    justify-content: center;
    border-radius: 22px;
    height: fit-content;
    padding: 2rem 0;
  }

  @include for-xl-only{
    height: 418px;
  }

  .banner_text{
    z-index: 1;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include for-phone-only{
      width: 90%;
      margin: 20px 0;
    }

    p{
      text-transform: uppercase;
      font-family: 'Baloo Bhai',sans-serif;
      width: 80%;
      line-height: 58px;
      letter-spacing: 1px;
      font-size: 54px; // 56px;
      color: white;
      font-weight: 400;
      text-align: center;

      @include for-xl-only{
        font-size: 36px;
        font-weight: 400;
        line-height: 44px;
      }
      @include for-md-only{
        font-size: 32px;
        line-height: 36px;
        font-weight: 400;
      }
      @include for-phone-only{
        font-size: 20px;
        line-height: 24px;
      }
      @include for-sm-only{
        width: 100%;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
      }


    }

    .btn_cont{
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      margin: 1rem auto;

      @include for-phone-only{
        width: 100%;
        justify-content: center;
      }
      button{
        margin: 1rem;
        font-size: 18px ; // 18
        letter-spacing: 1px;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 249px;//249
        text-transform: uppercase;
        height: 80px; //80
        border-radius: 60px;
        background: white;
        cursor: pointer;
        color: black;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.16);



        @include for-xl-only{
          width: 239px;
          height: 80px;
          font-weight: 700;
          font-size: 16px;
          line-height: 37px;
        }
        @include for-md-only{
          width: 200px;
          height: 58px;
          font-weight: 700;
          font-size: 16px;
          line-height: 37px;
        }
        @include for-phone-only{
          width: 48%;
          font-weight: 700;
          font-size: 12px;
          line-height: 22;
          height: 49px;
          margin: 0 1rem;
        }
        @include for-sm-only{
          max-width: 150px;
          margin: 0 2px;
          letter-spacing: 0.66px;
        }
      }

      .app_store{
        width: 38px;
        height: 46px;
        margin-right: 1rem;

        @include for-md-only{
          width: 28px;
          height: 36px;
        }
        @include for-phone-only{
          width: 25px;
          height: 30px;
          margin: 0 0.5rem;
        }

        @include for-sm-only{
          width: 15px;
          height: 22px;
        }
      }

    }
  }
  .background{
    top: 0;
    left: -10%;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  .background,.gift,.star,.star2{
    position: absolute;
    object-fit: contain;
    @include for-phone-only{
      object-fit: cover;
    }
  }
  .gift{
    bottom: -100px;
    left: 15%;

    @include for-xl-only{
      width: 106px;
      left: 10%;
      bottom: -45px;
    }

    @include for-phone-only{
      width: 81px;
      left: 5%;
      bottom: -45px;
    }
  }
  .star{
    bottom: -45px;
    right: 20%;
    @include for-phone-only{
      display: none;
    }

    @include for-xl-only{
      display: none;
    }
  }
  .star2{
    scale: 0.5;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    @include for-phone-only{
      top: 0;
    }
  }

  .banner_img{
    z-index: 1;
    width: 40%;
    height: 100%;
    display: flex;

    @include for-phone-only{
      width: 200px;
      height: auto;
    }
    img{
      width: 100%;
      height: 100%;
      scale: 1.2;
      object-fit: contain;
    }
    .screenshot1{
      height: 100%;
      position: relative;
      top: -40px;
      z-index: 1;

      @include for-phone-only{
        top:0;
      }
    }
    .screenshot2{
      height: 100%;
      position: relative;
      left: -100px;
    }
  }
}