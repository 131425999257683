@import '../styles.module';
.happy_client_cont {
  font-family: "Poppins", sans-serif;
  margin: 8rem auto;
  border: 1.63px solid;
  border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.2) -0.05%, #FFFFFF 49.2%, rgba(255, 255, 255, 0.2) 100.05%);
  color: rgba(0, 0, 0, 1);

  @include for-phone-only{
    margin: 2rem auto;
  }

  .prev,.next{
    display: block;
    position: relative;
    //position: absolute;
    z-index: 2;
    cursor: pointer;
  }
  .prev{
    left:-20px;

    @include for-phone-only{
      left: 0;
    }
  }
  .next{
    right:-20px;
    transform: rotate(180deg);
    @include for-phone-only{
      right: 0;
    }
  }

  .client_parent_cont{
    display: flex;
    justify-content: center;
    width: calc($astro-content-width + 40px);
    margin: auto;

    @include for-md-only{
      width: calc(90% + 40px);
      margin: auto;
    }
  }
  .client_cont{
    //transform: translateX(-2.5%);
    @include hideScrollBar;
    padding: 2rem 2rem;
    width: 100%;
    margin: auto;
    display: flex;
    max-width: 1512px;
    position: relative;
    gap: 0.75rem;
    overflow-x: scroll;
    @include for-xl-only{
      @include setContentWidth;
      width: 100%;
      margin: auto;
      transform: translateX(0);
    }


    @include for-phone-only{
      @include setContentWidth;
      overflow-x: scroll;
      padding-left: 1rem;
      padding-right: 1rem;
      @include hideScrollBar;
      margin: auto;
      transform: translateX(0);
    }
    //align-items: center;

    .each_client{
      position: relative;
      min-width: 550px;
      //margin: auto 2rem;
      box-shadow: 4px 4px 14px 0px rgba(0, 0, 0, 0.16);
      background: white;
      border-radius: 30px;
      padding: 1rem;

      @include for-xl-only{
        min-width: 500px;
        height: 300px;
      }


      @include for-phone-only{
        min-width: 235px;
        height: 400px;
        border-radius: 14px;
        overflow: hidden;
        padding: 0.5rem;
      }

      .quote{
        color: rgba(219, 219, 219, 0.3);
        position: absolute;
        top: 10%;
        left: 10%;
        font-size: 30pc;

        @include for-phone-only{
          width: 40px;
        }

        @include for-xl-only{
          width: 85px;
        }
      }
      .review{
        width: 90%;
        font-weight: 300;
        //font-size: 1.45vw; //28
        font-size: 20px; //28
        line-height: 30px;
        margin: 1rem auto;

        @include for-xl-only{
          font-size: 13px;
          font-weight: 300;
          line-height: 19px;
          margin: 0rem auto 0.5rem;
        }

        @include for-phone-only{
          font-size: 11px;
          line-height: 19px;
          margin: 0 auto;
        }
      }
      .client_img{
        margin-left: auto;
        width: 168px;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
        object-fit: cover;

        @include for-xl-only{
          width: 70px;
        }
        @include for-phone-only{
          width: 85px;
          position: relative;
          top:-20px;
          left: 20px;
        }
      }

      .tag{
        background: rgba(255, 184, 114, 0.3);
        border: 30px;
        padding: 0 2rem;
        height: 49px;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 400;
        line-height: 49px;
        @include for-phone-only{
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          width: 47px;
          border-radius: 12px;
          font-size: 8px;
          line-height: 12px;
          height: 19px;
          padding: 0 1rem;
        }
      }

      .details{
        width: 90%;
        margin: 0 auto 1rem auto;
        display: flex;
        align-items: center;
        justify-content: space-between;


        @include for-phone-only{
          margin: 1rem auto;
        }

        .name{
          p:first-child{
            //font-size: 1.25vw; //24
            font-size: 20px; //24
            font-weight: 600;
            line-height: 1.875;

            @include for-phone-only{
              font-size: 10px;
              line-height: 16px;
            }

            @include for-xl-only{
              font-size: 14px;
              line-height: 19px;
            }
          }
          p:nth-child(2){
            //font-size: 0.833vw; //28
            font-size: 14px; //28
            color: rgba(0, 0, 0, 0.6);
            font-weight: 400;
            line-height: 24px;
            @include for-phone-only{
              font-size: 8px;
              line-height: 12px;
            }
            @include for-xl-only{
              font-size: 10px;
              line-height: 15px;
            }
          }
        }
      }
    }
  }
}