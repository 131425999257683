@import "../styles.module";
.banner_btn {
  width: 100%;
  outline: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 90px; //90px;
  max-width: 610px; //610px;
  color: black;
  font-size: 24px; //24
  font-weight: 600;
  letter-spacing: 1px;
  margin: 12px 0 12px 0;
  text-transform: uppercase;
  background: white;
  border-radius: 54px;
  box-shadow: 0px 7px 8px 0px rgba(0, 0, 0, 0.16);
  border: 3px solid rgba(255, 184, 114, 1);
  //border-width: 3px;
  //border-style: solid;
  //border-image-slice: 1;
  //border-image-source: linear-gradient(41.25deg, #FF6132 1.02%, #FFB872 86.11%);
  //border-image: linear-gradient(rgba(255, 184, 114, 1) 0%, rgba(255, 184, 114, 1) 100%) 1;

  @include for-xl-only {
    //max-width: 404px;
  }
  .astrologer {
    margin: 0 0.5rem;
    background: -webkit-linear-gradient(
      41.25deg,
      #ff6132 1.02%,
      #ffb872 86.11%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .rate {
    background: -webkit-linear-gradient(
      41.25deg,
      #ff6132 1.02%,
      #ffb872 86.11%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: flex;
    align-items: center;

    img {
      width: 14px;
      aspect-ratio: 1;
      margin: 0 1px 0 1px;
    }
  }
  span {
    //background: linear-gradient(41.25deg, #FF6132 1.02%, #FFB872 86.11%);
  }
  .icon_cont {
    height: 75%;
    border-radius: 50%;
    margin: 0 0.5rem;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(41.25deg, #ff6132 1.02%, #ffb872 86.11%);
    img {
      width: 60%;
    }
  }

  @include for-xl-only {
    height: 60px;
    border-radius: 40px;
    border: 2px solid rgba(255, 184, 114, 1);
    font-size: 16px;
    //line-height: 23px;
    //letter-spacing: 0.66px;
    font-weight: 800;
  }
  @include for-md-only {
    font-size: 14px;
  }
  @include for-sm-only {
    font-size: 12px;
  }
  @include for-phone-only {
    height: 45px;
    border-radius: 27px;
    width: 80%;
    border: 1.5px solid rgba(255, 184, 114, 1);
  }
}

.banner_main_cont {
  @include setContentWidth;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  text-transform: uppercase;

  @include for-phone-only {
    margin: 0 auto 2rem auto;
  }

  .astroLinksContainer {
    display: flex;
    overflow-x: scroll;
    width: 100%;
    background: linear-gradient(190.46deg, #e2affa 0%, #6680fe 100%);
    box-shadow: rgba(0, 0, 0, 0.16) 4px 4px 14px 0px;
    border-radius: 50px;
    align-items: center;
    justify-content: space-evenly;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;

    @include for-phone-only {
      font-size: 10px;
    }

    .astroLink {
      padding: 10px 20px;

      @include for-phone-only {
        padding: 7px 10px;
      }

      .newTag {
        position: absolute;
        top: 0;
        right: 0;
        color: #ffffff;
        background: linear-gradient(125.75deg, #ffa767 -8.85%, #eb468b 90.19%);
        border-radius: 100px;
        font-size: 9px;
        padding: 0 5px;
        font-weight: 500;
        text-transform: capitalize;
        // margin-top: -20px;

        @include for-phone-only {
          font-size: 6px;
          padding: 0 3px;
        }
      }

      cursor: pointer;
      position: relative;

      .sublinkContainer {
        display: none;
        position: fixed;
        border-radius: 10px;
        background: #fff;
        box-shadow: rgba(0, 0, 0, 0.16) 4px 4px 14px 0px;
        margin-top: 44px;
        z-index: 5;
        left: 0;
        width: 300px;
        color: #000;
        max-height: 300px;
        overflow: scroll;

        @include for-phone-only {
          margin-top: 29px;
        }

        .subLink {
          padding: 20px;
          @include for-phone-only {
            padding: 10px;
          }
          cursor: pointer;
          font-weight: 500;
        }
      }

      &:hover {
        .sublinkContainer {
          display: block;
        }
      }
    }
  }

  .planet_img {
    width: 150px;
    aspect-ratio: 1;
    object-fit: contain;
    position: absolute;
    bottom: -55px;
    left: -65px;

    @include for-ipad-only {
      display: none;
    }

    @include for-md-only {
      width: 100px;
      bottom: -35px;
      left: -45px;
    }
  }

  .banner_cont {
    position: relative;
    background: linear-gradient(190.46deg, #e2affa 0%, #6680fe 100%);
    border-radius: 50px;
    height: 80vh;
    max-height: 743px;
    display: flex;
    align-items: center;
    padding: 1rem;
    justify-content: space-between;
    margin-top: 30px;

    @include for-xl-only {
      max-height: 552px;
      border-radius: 28px;
    }
    @include for-phone-only {
      width: 100%;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      padding: 1rem 0;
      max-height: 596px;
      border-radius: 28px;
    }
    & > div {
      width: 44%;
      margin: 0 auto;
      height: 90%;
      border-radius: 12px;

      @include for-phone-only {
        @include setContentWidth;
        height: auto;
      }
    }

    .banner_text {
      display: flex;
      flex-direction: column;
      justify-content: center;

      h1 {
        font-weight: 700;
        letter-spacing: 1px;
        //font-size: 2.9vw; // 56
        font-size: 54px; // 56
        line-height: 56px;
        margin: 1rem 0 0.5rem 0;
        text-shadow: 0 4px rgba(0, 0, 0, 0.16);

        @include for-xl-only {
          font-size: 40px;
          line-height: 44px;
        }
        @include for-phone-only {
          font-size: 24px;
          line-height: 28px;
          text-shadow: none;
          text-align: center;
          width: 90%;
          margin: 1rem auto;
        }
      }

      h2 {
        letter-spacing: 1px;
        margin: 1rem 0;
        font-weight: 400;
        font-size: 20px; //20px;
        line-height: 28px;
        text-transform: capitalize;

        @include for-xl-only {
          font-size: 14px;
          line-height: 21px;
        }
        @include for-phone-only {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          width: 90%;
          margin: 0.5rem auto 1rem;

          span {
            font-weight: 400;
          }
        }
        span {
          font-weight: 600;
        }
      }

      @include for-phone-only {
        width: 100%;

        .banner_btn {
          width: 90%;
          max-width: 320px;
          margin: 0.5rem auto;
          font-size: 12px;
          font-weight: 800;
          line-height: 18px;

          span {
            margin: 0 0 0 4px;
          }
        }
      }
    }
  }

  .banner_slider {
    width: 42%;
    position: relative;

    .slider_img_cont_dots {
      width: 100%;
      height: 20%;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      @include for-phone-only {
        margin-top: 20px;
        bottom: 0px;
      }
      & > span {
        width: 10px;
        height: 10px;
        margin-right: 10px;
        background-color: rgba(255, 255, 255, 0.6);
        border-radius: 50%;
        transition: all 1000ms ease;
      }

      .selected {
        width: 32px;
        border-radius: 4px;
        background-color: white;
      }
    }
    @include hideScrollBar;
    display: flex;
    align-items: center;
    overflow: hidden;
    //background: red;
    .slider_img_cont {
      position: relative;
      transition: all 1000ms ease-in-out;
      height: 90%;
      min-width: 100%;
      display: flex;
      //margin: 0 20px;

      .slider_img_prev,
      .slider_img_next,
      .empty {
        height: 100%;
        width: 20px;
        object-fit: cover;
        //background: red;
      }

      .play_btn {
        width: 120px;
        aspect-ratio: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        cursor: pointer;
        transform: translate(-50%, -50%);

        @include for-phone-only {
          width: 52px;
        }
        @include for-xl-only {
          width: 79px;
        }
      }
      .slider_img {
        //margin: 0;
        //padding: 0 20px;
        width: calc(100% - 0px);
        border-radius: 12px;
        object-fit: contain;
      }
    }
  }

  .quick_links_cont {
    margin: 3rem 0;
    padding: 1rem 0;
    width: 100%;
    //justify-content: space-between;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    cursor: pointer;
    @include hideScrollBar;
    overflow: scroll;

    .quick_link_overlay {
      &:before {
        content: "Coming Soon";
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgb(0 0 0 / 62%);
        color: #e3e3e3;
        padding: 20px;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 400;
        z-index: 5;
        justify-content: center;
        align-items: center;
      }
    }

    .prev,
    .next {
      position: absolute;
      z-index: 2;
      cursor: pointer;

      @include for-sm-only {
        display: none;
      }
    }
    .prev {
      left: 8%;

      @include for-md-only {
        left: 20px;
      }
    }
    .next {
      right: 8%;
      @include for-md-only {
        right: 20px;
      }
      transform: rotate(180deg);
    }

    @include for-phone-only {
      @include hideScrollBar;
      margin: 2rem 0;
      overflow-x: scroll;
    }
    //gap: 12px;

    .quick_link:nth-child(5) {
      p {
        width: 50%;
        @include for-xl-only {
          //max-width: 60px;
        }
      }
    }
    .quick_link {
      width: 25%;
      height: 262px;
      gap: 10px;
      position: relative;
      border-radius: 22px;
      padding: 16px;
      overflow: hidden;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 217px;

      @include for-xl-only {
        min-width: 175px;
        height: 151px;
      }

      @include for-phone-only {
        height: 86px;
        padding: 6px 16px;
        border-radius: 12px;
        box-shadow: none;
        gap: 0;
        min-width: 150px;
        //margin-right: 1rem;
      }

      p {
        width: 90%;
        height: 40%;
        text-transform: capitalize;
        vertical-align: bottom;
        color: white;
        line-height: 30px;
        font-weight: 500;
        font-size: 24px; //24
        //font-size: 24px;
        z-index: 2;
        position: relative;
        font-family: "Poppins", sans-serif;
        word-break: break-word;

        @include for-xl-only {
          font-size: 16px;
          line-height: 20px;
          width: 90%;
          max-width: 100px;
        }

        @include for-phone-only {
          font-size: 13px;
          line-height: 18px;
          height: auto;
          margin-bottom: 4px;
        }
      }

      .quick_link_icon {
        width: 52px;
        height: 42px;
        margin-bottom: 10px;
        object-fit: contain;
        z-index: 2;
        @include for-phone-only {
          width: 20px;
          aspect-ratio: 1;
          margin-bottom: 0;
        }

        @include for-xl-only {
          width: 32px;
          height: 26px;
        }
      }
      .gradient_back {
        z-index: 1;
        transition: all 000ms ease-in;
      }
      .quick_link_img {
        //z-index: -1;
      }
      .quick_link_img,
      .gradient_back {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0;
        left: 0;
      }
    }
  }
}
