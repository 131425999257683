@import '../styles.module';
.live_session_cont{
  font-family: "Poppins", sans-serif;
  @include setContentWidth;
  margin: auto;
  position: relative;
  border-radius: 50px;
  padding: 4rem;
  background: linear-gradient(190.46deg, rgba(226, 175, 250, 0.6) 0%, rgba(102, 128, 254, 0.06) 100%);
  border: 1.63px solid;
  border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.2) -0.05%, #FFFFFF 49.2%, rgba(255, 255, 255, 0.2) 100.05%);
  color: rgba(0, 0, 0, 1);

  @include for-md-only{
    padding: 2rem;
    border-radius: 20px;
    min-height: 300px;
  }
  @include for-phone-only{
    padding: 1rem;
    border-radius: 20px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
  }
  .background{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


  .sessions_cont{
    margin: 4rem 0;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;

    @include for-phone-only{
      @include hideScrollBar;
      margin: 1rem 0;
      display: flex;
      align-items: center;
      overflow: scroll;
      gap: 1rem;
    }

  }
}