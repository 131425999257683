@import "../../../modules/Astro/styles.module";
.each_session {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  aspect-ratio: 0.7;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  cursor: pointer;

  .expert_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    //opacity: 0.8;
    //mix-blend-mode: darken;
  }

  @include for-phone-only {
    height: 187px;
    min-width: 136px;
    padding: 1rem;
  }
  & > div {
    position: relative;
    z-index: 1;
  }

  .layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }

  .session_top {
    font-weight: 400;
    font-size: 14px; //14
    color: white;
    line-height: 21px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include for-phone-only {
      font-size: 8px;
    }

    @include for-xl-only {
      font-size: 10px;
    }

    .live {
      background: rgba(0, 0, 0, 0.3);
      border-radius: 30px;
      //font-size: 14px;
      font-weight: 400;
      padding: 0 4px;
      display: flex;
      align-items: center;

      div {
        width: 8px;
        aspect-ratio: 1;
        border-radius: 50%;
        margin-right: 4px;
        margin-left: 4px;
        background: linear-gradient(108.74deg, #ffa767 -12.1%, #eb468b 95.64%);
      }
    }
  }

  .expert_details {
    margin-top: auto;
    display: flex;
    align-items: center;
    font-weight: 500;
    //font-size: 1.354vw; //26px;
    font-size: 24px;
    img {
      margin-left: 0.5rem;
      @include for-phone-only {
        width: 12px;
      }
    }

    @include for-xl-only {
      font-size: 16px;
    }
    @include for-phone-only {
      font-size: 12px;
    }
  }
  .expert_rating {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    margin-top: 1rem;

    @include for-xl-only {
      margin-top: 0.5rem;
      font-size: 10px;
    }

    @include for-phone-only {
      margin-top: 0.5rem;
      img {
        width: 30px;
        margin-left: 0;
      }
    }
    img {
      margin-left: 10px;
    }
  }
}
